var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"二级考点","visible":_vm.dialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"1200rem","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-table',{ref:"theTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"520rem","tree-props":{children: 'children'},"row-key":"id"},on:{"row-click":_vm.getOpenDetail}},[_c('el-table-column',{attrs:{"prop":"text","align":"left","label":"考点"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.check_point_2_desc?row.check_point_2_desc:row.check_point_3_desc)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"check_point_2_start","align":"left","label":" 考点星级"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},_vm._l((row.check_point_2_start),function(item,index){return _c('img',{key:index,attrs:{"src":require("@/assets/icons/icon-星星.png")}})}),0),_c('div',{staticClass:"checkpoint"},_vm._l((row.check_point_3_start),function(item,index){return _c('img',{key:index,attrs:{"src":require("@/assets/icons/icon-星星.png")}})}),0)]}}])}),_c('el-table-column',{attrs:{"prop":"text","align":"left","label":"易错指数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.error_prone)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.doPaper(row)}}},[_vm._v(" 开始训练 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }