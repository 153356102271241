<template>
  <div>
    <el-dialog title="三级考点"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                height="520rem"
                ref="theTable"
                default-expand-all
                @row-click="getOpenDetail"
                :tree-props="{children: 'children'}"
                row-key="id"
                style="width: 100%">
        <el-table-column prop="text"
                         align="left"
                         width="500rem"
                         label="考点">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.check_point_2_desc?row.check_point_2_desc+'(二级考点)':row.check_point_3_desc+'(三级考点)'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="check_point_2_start"
                         align="left"
                         label=" 考点星级">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.check_point_2_start}}
            </div>
            <div class="checkpoint">
              {{row.check_point_3_start}}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="text"
                         align="left"
                         label="易错指数">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.error_prone}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <div class="btn"
                 v-show="row.check_point_3_desc"
                 @click="doPaper(row)">
              开始训练
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import { getTwoCheckpoint } from '@/api/checkpoint.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
      type: ''
    }
  },
  methods: {
    getOpenDetail (row) {
      this.$refs.theTable.toggleRowExpansion(row);
    },
    handleClose () {
      this.dialogVisible = false
    },
    doPaper (row) {

      let params = {
        check_point_2_id: row.check_point_2_id,
        check_point_3_id: row.check_point_3_id,
        selecting_checkpoint: 0,
        type: 7,
      }
      if (row.check_point_3_id) {
        params.check_point_2_id = ''
      }
      if (this.type) {
        params.type = this.type
      }
      this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
    },
    async initList (row) {

      let arr = [...row]

      arr.map(item => {
        if (item.check_point_2_id) {
          // 去除非数字字符，只保留数字部分
          let str = item.check_point_2_id
          const numStr = str.replace(/\D/g, ''); // 使用正则表达式匹配非数字字符并替换为空字符串

          // 将剩余的数字部分转换为整数
          const num = parseInt(numStr);
          // console.log('nium', num)
          item.id = num
        }
        // console.log('item', item.children[0])
        if (item.children.length > 0) {
          item.children.map(childrenitem => {
            if (childrenitem.check_point_3_id) {
              let str = childrenitem.check_point_3_id
              const numStr = str.replace(/\D/g, "");
              const num = parseInt(numStr);
              childrenitem.id = num
            }
          })
        }
      })

      this.tableData = arr
      console.log(this.tableData, 'this.tableData')
    }
    // async save () {
    //   await collect(this.form)
    //   this.handleClose()
    //   this.$message.success('收藏成功，可前往错题本查看')
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog {
  border-radius: 20rem;
  min-height: 600rem;
  margin-top: 198rem !important;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  // text-indent: 60rem;
  display: flex;
  font-size: 20rem;
}

.checkpoint {
  font-weight: bold;
  color: #333333;
  font-size: 20rem;
}
.btn {
  width: 140rem;
  height: 44rem;
  background: #2196f3;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-weight: bold;
  color: #ffffff;
  text-indent: 0;
  cursor: pointer;
}
</style>